import { PriceRule } from 'component-library/ProductDetails/ProductPrice';
import { ProductAttribute } from 'features/pdp';
import { graphql } from 'gatsby';
import { GiftcardAmount, PriceList, TierPrices } from 'features/pdp/types/event';
import { StrikethroughValues } from './StrikethroughValues';

export const GiftCardProductType = 'giftcard';

export interface ProductFilter {
  name: string;
  values: string[];
}

export type ConfigurableAttribute = {
  id: number | string;
  label: string;
  title?: string;
  type: string;
  code?: string;
  options:
    | {
    [id: string]: {
      type: number;
      value: string;
      label: string;
    };
  }
    | null
    | undefined;
};

export type CustomOption = {
  default_title: string;
  display_name: any;
  is_require: string;
  depends_on_option?: any;
  depends_on_values?: any;
  price: any;
  name: string;
  values: CustomOptionData[];
  filterAttribute: string;
  use_case_option?: string;
  use_case_show_parent_on_clp: boolean;
  react_pdp_input_type: string;
  title: string;
};

export type CustomOptionData = {
  default_title: string;
  display_name: any;
  hover_image: string;
  hover_description: any;
  hover_label: any;
  hover_price: any;
  in_stock: string;
  visible_on_pdp: string;
  depends_on_option?: any;
  depends_on_values?: any;
  use_case_default_image_url?: string;
  use_case_product_title?: string;
  clp_position: string;
  label: string;
  price: string;
  filterValue: string;
  title: string;
};

export type Rating = {
  totalReviews: number;
  score: number;
};

interface ProductCallouts {
  callout1?: string;
  callout2?: string;
  callout3?: string;
}

export interface Product {
  id?: string;
  productId: number;
  url: string;
  sku: string;
  name: string;
  shortDescription?: string;
  inStock?: boolean;
  description: string;
  descriptionAuDifference?: string;
  editorVersion: string;
  isEditable: boolean;
  enterEditor: boolean;
  productType?: string;
  reportingProductCategory: string;
  hammerCategory?: string;
  reportingProductType: 'simple' | 'configurable' | 'giftcard' | 'books';
  reportingProductLine: string;
  badges: string[];
  price: number;
  lowestPrice: number;
  priceList: PriceList;
  tierPrices?: TierPrices;
  image: string;
  clpLink?: string;
  urlKey: string;
  metaTitle: string;
  metaDescription: string;
  canonicalUrl: string;
  mediaUrl?: string;
  gatsbyPageExists: boolean;
  clpHoverImage: string;
  clpHoverVideoId: string;
  clpCarouselImages: string[];
  giftcardAmounts?: GiftcardAmount[];
  catalogPriceRules?: PriceRule[];
  visibility: string;
  filters: ProductFilter[];
  livePreviewAttributes: string[];
  customOptions: CustomOption[];
  attributes?: ProductAttribute[];
  isMobileFriendly: boolean;
  mobileAvailability: string;
  addToCartLabel: string;
  stickyCTAMessaging: string;
  promotionalMessage?: string;
  accordionTitleShippingTime?: string;
  productDetailsExtraLabel?: string;
  shippingInfoHtml?: string;
  detailsHtml?: string;
  pricingDetailsHtml?: { [key: string]: { [key: string]: number } };
  pagePricingDetails?: string;
  printPricing?: { [key: string]: Record<string, unknown> };
  additionalFoilPrice?: { [key: string]: { [key: string]: number } };
  FAQHtml?: string;
  productDetailsExtraContent?: string;
  hasDustJacket?: boolean;
  strikethroughValues?: StrikethroughValues;
  customProductType?: string;
  shippingInformation: string;
  shippingAlert: string;
  sla: string;
  outOfStockPermutations: string[];
  productCallout: ProductCallouts | null;
}

export interface RelatedProduct {
  position: number;
  productRelationType: 'upsell' | 'related';
  product: Product;
}

export type CategorySLA = '1 Day SLA' | '2 Day SLA' | '3 Day SLA' | '4 Day SLA' | '5 Day SLA' | '6 Day SLA';

export const isCategorySLA = (categoryName: string): categoryName is CategorySLA => {
  return ['1 Day SLA', '2 Day SLA', '3 Day SLA', '4 Day SLA', '5 Day SLA', '6 Day SLA'].includes(categoryName);
};

export const BaseProductFragment = graphql`
  fragment BaseProduct on Product {
    sku
    name
    description
    descriptionAuDifference
    isEditable
    reportingProductCategory
    reportingProductType
    reportingProductLine
    badges
    urlKey
    price
    lowestPrice
    image
    metaTitle
    metaDescription
    productId
    canonicalUrl
    gatsbyPageExists
    clpHoverImage
    clpHoverVideoId
    clpCarouselImages
    visibility
    clpLink
    filters {
      name
      values
    }
    strikethroughValues {
      discountAmount
      discountType
      promoCode
    }
  }
`;
