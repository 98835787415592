import { useState, useEffect, useCallback } from 'react';
import { PaginatedResponse, PaginationData } from 'au-js-sdk/lib/api/models/PaginatedResponse';
import { Project, DehydratedProject } from 'au-js-sdk/lib/models/Project';
import { ProjectStatusV1, ProjectDataV1 } from 'au-js-sdk/lib/models/ProjectV1';
import { ProjectStatusV2 } from 'au-js-sdk/lib/models/Project';
import { HydratedProjectV1 } from '../../types/projects';
import { Resource } from 'au-js-sdk/lib/types/relatives';
import { useProjectsRequest, UseProjectsRequestProps } from './useProjectsRequest';

export type UseProjectsProps = UseProjectsRequestProps & {
  fetchOnMount?: boolean;
};

const useProjects = (params: UseProjectsProps) => {
  const { fetchOnMount = true, ...config } = params;

  const [projects, setProjects] = useState<DehydratedProject[]>([]);
  const [paginationData, setpaginationData] = useState<PaginationData>({} as PaginationData);
  const [isFetching, setIsFetching] = useState(false);
  const projectsService = useProjectsRequest(config);

  const fetchProjectsWithoutLoading = useCallback(
    async (
      pageNumber?: number,
      pageSize = config.pageSize,
      lastEvaluatedKey?: Record<string, string>,
      lastEvaluatedKeyV1?: Record<string, string>
    ): Promise<void> => {
      const response = await projectsService.fetchProjects({
        ...config,
        page: pageNumber,
        pageSize,
        lastEvaluatedKey,
        lastEvaluatedKeyV1
      });
      const projects = getProjectsList(response);
      const paginationData = getPaginationData(response);
      setProjects(projects);
      setpaginationData(paginationData);
    },
    [projectsService]
  );

  const fetchProjects = useCallback(
    async (
      pageNumber?: number,
      pageSize = config.pageSize,
      lastEvaluatedKey?: Record<string, string>,
      lastEvaluatedKeyV1?: Record<string, string>
    ): Promise<void> => {
      setIsFetching(true);
      await fetchProjectsWithoutLoading(pageNumber, pageSize, lastEvaluatedKey, lastEvaluatedKeyV1);
      setIsFetching(false);
    },
    [projectsService]
  );

  const fetchProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<HydratedProjectV1 | Project | ProjectDataV1 | Resource> => {
      setIsFetching(true);
      const response = projectsService.fetchProject(projectId, schemaVersion);
      setIsFetching(false);
      return response;
    },
    [projectsService]
  );

  const deleteProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2', editProjectAfter = false): Promise<boolean | string> => {
      setIsFetching(true);
      const response = await projectsService.deleteProject(projectId, schemaVersion);
      await fetchProjectsWithoutLoading(paginationData.pageNumber, paginationData.pageSize);
      if (!editProjectAfter) {
        setIsFetching(false);
      }
      return response;
    },
    [projectsService]
  );

  const renameProject = useCallback(
    async (projectId: string, newName: string, schemaVersion?: '1' | '2'): Promise<void> => {
      const newProject = await projectsService.updateProjectName(projectId, newName, schemaVersion);
      const oldProject = projects.find(p => p.id === projectId);
      if (oldProject && newProject?.name) {
        oldProject.name = newProject.name;
      }
      setProjects([...projects]);
    },
    [projectsService]
  );

  const duplicateProjectAndFetchAll = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<HydratedProjectV1 | Project | ProjectDataV1 | Resource> => {
      setIsFetching(true);
      const duplicateProjectResult = await projectsService.duplicateProject(projectId, schemaVersion);
      const [fetchDuplicateProjectResult] = await Promise.all([
        projectsService.fetchProject(duplicateProjectResult.projectId, schemaVersion),
        fetchProjectsWithoutLoading(paginationData.pageNumber, paginationData.pageSize)
      ]);
      setIsFetching(false);
      return fetchDuplicateProjectResult;
    },
    [projectsService]
  );

  const duplicateProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<HydratedProjectV1 | Project | ProjectDataV1 | Resource> => {
      const duplicateProjectResult = await projectsService.duplicateProject(projectId, schemaVersion);

      const fetchDuplicateProjectResult = await projectsService.fetchProject(
        duplicateProjectResult.projectId,
        schemaVersion
      );

      return fetchDuplicateProjectResult;
    },
    [projectsService]
  );

  const restoreProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<void> => {
      setIsFetching(true);
      await projectsService.restoreProject(projectId, schemaVersion);
      await fetchProjectsWithoutLoading(paginationData.pageNumber, paginationData.pageSize);
      setIsFetching(false);
    },
    [projectsService]
  );

  const updateProjectStatus = useCallback(
    async (projectId: string, status: ProjectStatusV1 | ProjectStatusV2, schemaVersion?: '1' | '2'): Promise<void> => {
      setIsFetching(true);
      await projectsService.updateProjectStatus(projectId, status, schemaVersion);
      await fetchProjectsWithoutLoading(paginationData.pageNumber, paginationData.pageSize);
      setIsFetching(false);
    },
    [projectsService]
  );

  useEffect(() => {
    if (fetchOnMount && config.flashId && config.token) {
      fetchProjects(1);
    }
  }, []);

  return {
    projects,
    isFetching,
    paginationData,
    fetchProjects,
    fetchProject,
    deleteProject,
    renameProject,
    duplicateProject,
    duplicateAndFetchProjects: duplicateProjectAndFetchAll,
    restoreProject,
    updateProjectStatus
  };
};

// returns projects for fetch response
const getProjectsList = ({ result }: { result: DehydratedProject[] }) => result;

// returns pagination paginationData for fetch response
const getPaginationData = ({
  paginationData
}: PaginatedResponse): PaginationData & {
  isFirstPage: boolean;
  isLastPage: boolean;
} => ({
  ...paginationData,
  isFirstPage: paginationData.pageNumber === 1,
  isLastPage: paginationData.pageNumber === paginationData.totalPages
});

export default useProjects;
