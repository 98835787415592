import { useCallback } from 'react';
import {
  getAllProjects,
  getProject,
  updateProjectName as updateProjectNameRequest,
  duplicateProject as duplicateProjectRequest,
  deleteProject as deleteProjectRequest,
  restoreProject as restoreProjectRequest,
  updateProjectStatus as updateProjectStatusRequest
} from 'au-js-sdk/lib/api/requests/projects';
import { Project, DehydratedProject } from 'au-js-sdk/lib/models/Project';
import { ProjectStatusV2 } from 'au-js-sdk/lib/models/Project';
import { PaginatedResponse } from 'au-js-sdk/lib/api/models/PaginatedResponse';
import { Resource } from 'au-js-sdk/lib/types/relatives';
import { DuplicateProjectResponse } from 'au-js-sdk/lib/api/models/cc/project/DuplicateProjectResponse';
import { GetAllProjectsRequest } from 'au-js-sdk/lib/api/requests/projects/getAllProjects';
import { ProjectDataV1 } from 'au-js-sdk/lib/models/ProjectV1';

export const useCrudCoreProjectsRequest = (config: GetAllProjectsRequest) => {
  const fetchProjects = useCallback(
    async (params: GetAllProjectsRequest): Promise<PaginatedResponse<DehydratedProject>> => {
      const res = await getAllProjects({
        ...config,
        ...params
      });
      if (res.isLeft()) {
        throw res.value;
      }
      return res.value;
    },
    [config]
  );

  const fetchProject = useCallback(
    async (projectId: string): Promise<Project | ProjectDataV1 | Resource> => {
      const res = await getProject(config.flashId, config.token, projectId);
      if (res.isLeft()) {
        throw res.value;
      }
      return res.value;
    },
    [config.flashId, config.token]
  );

  const deleteProject = useCallback(
    async (projectId: string): Promise<string> => {
      const res = await deleteProjectRequest(config.flashId, config.token, projectId);
      if (res.isLeft()) {
        throw res.value;
      }
      return res.value;
    },
    [config.flashId, config.token]
  );

  const duplicateProject = useCallback(
    async (projectId: string): Promise<DuplicateProjectResponse> => {
      const res = await duplicateProjectRequest(config.flashId, config.token, projectId);
      if (res.isLeft()) {
        throw res.value;
      }
      return res.value;
    },
    [config.flashId, config.token]
  );

  const restoreProject = useCallback(
    async (projectId: string): Promise<Project> => {
      const res = await restoreProjectRequest(config.flashId, config.token, projectId);
      if (res.isLeft()) {
        throw res.value;
      }
      return res.value;
    },
    [config.flashId, config.token]
  );

  const updateProjectStatus = useCallback(
    async (projectId: string, status: ProjectStatusV2): Promise<Project> => {
      const res = await updateProjectStatusRequest(config.flashId, config.token, projectId, status);
      if (res.isLeft()) {
        throw res.value;
      }
      return res.value;
    },
    [config.flashId, config.token]
  );

  const updateProjectName = useCallback(
    async (projectId: string, newProjectName: string): Promise<Project> => {
      const res = await updateProjectNameRequest(config.flashId, config.token, projectId, newProjectName, true);

      if (res.isLeft()) {
        throw res.value;
      }
      return res.value;
    },
    [config.flashId, config.token]
  );

  return {
    fetchProjects,
    fetchProject,
    deleteProject,
    duplicateProject,
    restoreProject,
    updateProjectStatus,
    updateProjectName
  };
};
