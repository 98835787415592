import React from 'react';
import { Product, RelatedProduct } from 'types/Product';
import styled from 'styled-components';
import { mediaQuery } from 'component-library';

import YotpoReviews from 'components/Yotpo/YotpoReviews';
import BuyBanner from 'components/BuyBanner';
import SeoBlock from 'components/SeoBlock';
import { RelatedProducts } from 'components/RelatedProducts';
import { selectUpsellProducts, Upsell } from 'components/Upsell';
import Breadcrumbs from 'components/Breadcrumbs';
import { getYotpoApiKey } from '../../../helpers/env';
import { MarketingBannerContainer } from './styled';
import { Show } from 'components/Functional';

const MobileBreadcrumbs = styled(Breadcrumbs)`
  ${mediaQuery.medium`
    display: none;
`};
`;

interface BottomContentProps {
  breadcrumbs: [{ name: string; urlPath: string }];
  product: Product & {
    relatedProducts: RelatedProduct[];
    bannerImage?: string;
    seoBlock?: string;
  };
  relatedProducts: Product[];
  shouldHideBuyBanner?: boolean;
  showMobilePDPTest?: boolean;
}

export function BottomContent(props: BottomContentProps): JSX.Element {
  const { breadcrumbs, product, shouldHideBuyBanner, showMobilePDPTest, relatedProducts } = props;

  const upsellProducts = selectUpsellProducts(product);
  return (
    <>
      <Show when={shouldHideBuyBanner}>
        <BuyBanner className="secondary" />
      </Show>
      <MarketingBanner product={product} />
      <Show when={!showMobilePDPTest}>
        <RelatedProducts products={relatedProducts} />
      </Show>
      <Upsell products={upsellProducts} />
      <PDPSeoBlock product={product} />
      <YotpoReviews apiKey={getYotpoApiKey()} productId={`${product.productId}`} productName={product.name} />
      <MobileBreadcrumbs pages={breadcrumbs} />
    </>
  );
}

interface PDPSeoBlockProps {
  product: {} & {
    seoBlock?: string;
  };
}

function PDPSeoBlock(props: PDPSeoBlockProps) {
  const { product } = props;
  if (!product.seoBlock) {
    return null;
  }
  return (
    <SeoBlock
      content={product.seoBlock}
      css={`
        max-width: 1180px;
        padding: 1rem;
        margin: auto;
        display: none;
        ${mediaQuery.medium`
            display: block;
        `};
      `}
    />
  );
}

interface MarketingBannerProps {
  product: {} & {
    bannerImage?: string;
  };
}

function MarketingBanner(props: MarketingBannerProps) {
  const { product } = props;
  if (!product.bannerImage) {
    return null;
  }
  return <MarketingBannerContainer dangerouslySetInnerHTML={{ __html: product.bannerImage }} />;
}
