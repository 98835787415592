// EN-8821
export const SPLIT_IO_FEATURE_FLAG_REVIEWS_ON_PRODUCT_CARDS_DESKTOP = 'FeatureFlag-EN8821-Reviews_on_Product_Cards_Desktop';
export const SPLIT_IO_FEATURE_FLAG_REVIEWS_ON_PRODUCT_CARDS_DESKTOP_COOKIE = 'au_split_feature_flag_reviews_on_product_cards_desktop';
// EN-8953
export const SPLIT_IO_FEATURE_FLAG_MESSAGING_AT_CHECKOUT_FOR_FRAMES = 'FeatureFlag-EN8953-AB_test_Messaging_at_checkout_for_cart_with_frames';
export const SPLIT_IO_FEATURE_FLAG_MESSAGING_AT_CHECKOUT_FOR_FRAMES_COOKIE = 'au_split_feature_flag_messaging_at_checkout_for_frames';
// EN-9100
export const SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE = 'FeatureFlag-EN9100-Product_info_hierarchy_on_Mobile_PDP_-_AB_test';
export const SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE_COOKIE = 'au_split_feature_flag_product_info_hierarchy_on_mobile';
// EN-8964
export const SPLIT_IO_FEATURE_FLAG_PHOTO_BOOKS_NAVIGATION_DESKTOP = 'FeatureFlag-EN8964-Photo_Books_Navigation_AB_test_-_Desktop';
export const SPLIT_IO_FEATURE_FLAG_PHOTO_BOOKS_NAVIGATION_DESKTOP_COOKIE = 'au_split_feature_flag_photo_books_navigation_desktop';
// EN-9124
export const SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES = 'FeatureFlag-EN9124-PDP_AB_Test_mobile-Bigger_Images';
export const SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES_COOKIE = 'au_split_feature_flag_mobile_bigger_images';
// EN-9176
export const SPLIT_IO_FEATURE_FLAG_MOBILE_STICKY_CTA = 'FeatureFlag-EN9176-PDP_AB_test_Mobile_-_Sticky_CTA';
export const SPLIT_IO_FEATURE_FLAG_MOBILE_STICKY_CTA_COOKIE = 'au_split_feature_flag_mobile_sticky_cta';
// EN-9175
export const SPLIT_IO_FEATURE_FLAG_MOBILE_PDP = 'FeatureFlag-EN9175-PDP_AB_test_Mobile_-_Similar_products_block';
export const SPLIT_IO_FEATURE_FLAG_MOBILE_PDP_COOKIE = 'au_split_feature_flag_mobile_pdp';
