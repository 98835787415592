/**
 * This component has been extracted from the component-library to support
 * ProductBlock.
 * TODO: Eventually we should consider rolling these changes back upstream.
 */
import styled, { css } from 'styled-components';

export interface TypographyProps {
  color?: string
  fontFamily?: string
  lightBlue?: boolean
  alignment?: 'left' | 'center' | 'right'
  fontStyle?: 'italic' | 'bold'
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  marginBottom?: string
}

export const baseStyles = css<TypographyProps>`
  color: ${({ color = '#000' }) => color};
  font-weight: normal;
  line-height: 1.0;
  width: 100%;
  font-family: ${({ fontFamily }) => fontFamily};
  ${({ lightBlue }) => lightBlue && css`color: #629eba;`}
  ${({ alignment }) => alignment && css`text-align: ${alignment};`}
  ${({ fontStyle }) => fontStyle && css`font-style: ${fontStyle};`}
`;

export const StyledH3 = styled.h3<TypographyProps>`
  ${baseStyles};
  font-size: ${({ fontSize = '1.375rem' }) => fontSize};
  line-height: ${({ lineHeight = '2rem' }) => lineHeight};
`;

export const StyledP = styled.p<TypographyProps>`
  ${baseStyles};
  font-size: ${({ fontSize = '1rem' }) => fontSize};
  line-height: ${({ lineHeight = '1.35rem' }) => lineHeight};
  letter-spacing: ${({ letterSpacing = 'inherit' }) => letterSpacing};
  margin-bottom: ${({ marginBottom = 'inherit' }) => marginBottom};
`;
