import { useCallback } from 'react';
import { TrackingProp } from 'react-tracking';
import { useMeasureLoadTime, MeasureLoadTimeProps } from 'hooks/useMeasureLoadTime';
import { getLoadingMarkNames } from '../helpers/performance';
import { isProdEnv } from 'helpers/env';

interface TrackComponentLoadTimeProps extends Pick<MeasureLoadTimeProps, 'isLoading' | 'debounce'> {
  componentName: string;
  tracking: TrackingProp;
  cartId: number;
  email?: string;
}

const useTrackComponentLoadTime = ({
  isLoading,
  componentName,
  tracking,
  cartId,
  email = '',
  debounce
}: TrackComponentLoadTimeProps): void => {
  const markNames = getLoadingMarkNames(componentName);

  const handleLoadingStart = useCallback((startTime: number) => {
      performance.mark(markNames.start, { startTime });

      if (!isProdEnv()) {
        console.debug(`${componentName} component started loading at ${startTime}`);
      }
    },
    [componentName, markNames.start]);

  const handleLoadingFinish = useCallback((loadTime: number, endTime: number) => {
    const data = { component_name: componentName, load_time: loadTime, cart_id: cartId, email: email };
    tracking.trackEvent({ type: 'componentLoaded', data });

    performance.mark(markNames.end, { startTime: endTime });

    if (!isProdEnv()) {
      console.debug(`${componentName} component finished loading at ${endTime} after ${loadTime}ms`);
    }
  }, [cartId, componentName, email, markNames.end, tracking]);

  useMeasureLoadTime({
    isLoading,
    debounce,
    onLoadingStart: handleLoadingStart,
    onLoadingFinish: handleLoadingFinish
  });
};

export default useTrackComponentLoadTime;