import { RadioInputSelector, StyledH4, Subtext } from 'component-library';
import { optionGet } from 'faunctions';
import { showIf } from 'helpers/conditionals';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';
import React, { FormEvent, Fragment, useEffect, useState, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  cartItemsSelector,
  hasFreeShippingBeenAppliedSelector,
  isFreeShippingAppliedToCartSelector,
  shippingAddressSelector,
  totalSegmentsSelector
} from 'store/cart/selectors';
import {
  fetchShippingRatesRequest as _fetchShippingRatesRequest,
  setFreeShippingApplied as _setFreeShippingApplied
} from 'store/cart/actions';
import {
  shippingMethodSelector,
  shippingRatesSelector,
  shippingRatesLoadingStateSelector,
  shippingAddressLoadingStateSelector,
  isVirtualCartSelector
} from 'store/cart/selectors';
import { GlobalState, isFetching } from 'store/constants';
import {
  ErrorMessage,
  HeaderText,
  HolidayNoteText,
  ShippingMethodItemWrapper,
  ShippingMethodPrice,
  ShippingMethodsSkeleton,
  ShippingSelectionHeader,
  SubheaderText
} from './styled';
import { LoadingState } from 'store/constants';
import { ShippingMethod } from './styled/index';
import { Show } from 'components/Functional';
import { useWindowSize } from 'hooks/useWindowSize';
import { nrError, noShippingRatesError, noShippingRatesErrorMessage } from 'helpers/reporting/newrelic';
import QuoteDataCartItem from 'mage-swagfaces/quote/QuoteDataCartItem';
import Block from 'components/CMS/Block';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';
import { buildShippingMethodStr } from '../helpers';
import { useProductSLA } from '../../../hooks/useProductSLA';
import useAfterpay from 'features/afterpay/hooks/useAfterpay';
import { getEstimatedArrivalDateRange, getFilteredShippingRates, removeAlternateFromShippingMethodTitle } from '../../../helpers/shipping';
import { useSplitTreatment } from 'hooks/splits/useSplitTreatment';
import {
  SPLIT_IO_FEATURE_FLAG_MESSAGING_AT_CHECKOUT_FOR_FRAMES,
  SPLIT_IO_FEATURE_FLAG_MESSAGING_AT_CHECKOUT_FOR_FRAMES_COOKIE
} from 'constants/split';
import { FRAMES } from '../../../component-library/constants/products';
import './styles.css';
import useTrackComponentLoadTime from '../../../analytics/itly/hooks/useTrackComponentLoadTime';
import { TrackingProp } from 'react-tracking';
import { Cart__Patched } from '../../../pages/checkout/cart';
import { ComponentName } from 'constants/componentName';

interface ShippingTypeSelectorProps {
  shippingRates: QuoteDataShippingMethod[];
  shippingMethod: string;
  shippingRatesLoadingState: LoadingState;
  shippingAddressLoadingState: LoadingState;
  shippingAddress: CustomerDataAddress;
  hasFreeShippingBeenApplied: boolean;
  setFreeShippingApplied: (hasBeenApplied: boolean) => void;
  isVirtualCart: boolean;
  fetchShippingRatesRequest: () => void;
  showLoader?: boolean;
  isAmazonpay?: boolean;
  onChange: (e: FormEvent<HTMLFormElement>) => void;
  cartItems: QuoteDataCartItem[];
  totalSegments: QuoteDataTotalSegment[];
  updateShippingMethod: (carrier_code: string, method_code: string, isAmazonpay: boolean) => void;
  tracking: TrackingProp;
  cart: Cart__Patched;
}

const ShippingTypeSelector = ({
  shippingRates,
  shippingMethod,
  shippingRatesLoadingState,
  shippingAddressLoadingState,
  hasFreeShippingBeenApplied,
  setFreeShippingApplied,
  shippingAddress,
  onChange,
  updateShippingMethod,
  isVirtualCart,
  showLoader,
  isAmazonpay,
  cartItems,
  totalSegments,
  fetchShippingRatesRequest,
  tracking,
  cart
}: ShippingTypeSelectorProps) => {
  const [value, setValue] = useState(shippingMethod);
  const isFreeShippingAppliedToCart = useSelector(isFreeShippingAppliedToCartSelector);
  const windowSize = useWindowSize();

  const { getLongestSLA } = useProductSLA();
  const cartSkus = cartItems.map(cartItem => cartItem.sku);
  const longestSLA = getLongestSLA(cartSkus);

  const { isAfterpayCompleteStep } = useAfterpay();

  const isLoading = showLoader || isFetching(shippingRatesLoadingState) || isFetching(shippingAddressLoadingState);
  const isReadyForShippingRatesCheck =
    !isLoading && shippingRatesLoadingState === LoadingState.SUCCESS && !isVirtualCart;

  const SPLIT_IO_FEATURE_FLAG_SHIPPING_TEST = 'FeatureFlag-EN-8897-Shipping-Test';
  const SPLIT_IO_FEATURE_FLAG_SHIPPING_TEST_COOKIE = 'au_split_feature_flag_shipping_test';

  const { treatmentStatus: displayShippingTest } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_SHIPPING_TEST,
    SPLIT_IO_FEATURE_FLAG_SHIPPING_TEST_COOKIE
  );


  const filteredShippingRates = getFilteredShippingRates(shippingRates, displayShippingTest);

  const { treatmentStatus: showFrameMessagingSplit } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_MESSAGING_AT_CHECKOUT_FOR_FRAMES,
    SPLIT_IO_FEATURE_FLAG_MESSAGING_AT_CHECKOUT_FOR_FRAMES_COOKIE
  );

  const hasFrameInCart = cartItems.some(item => item.reporting_product_category === FRAMES);
  const showFrameMessaging = showFrameMessagingSplit && hasFrameInCart;

  useTrackComponentLoadTime({
    isLoading,
    componentName: ComponentName.SHIPPING_OPTIONS,
    tracking,
    cartId: cart.entity_id,
    email: cart.customer?.email
  });

  useEffect(() => {
    setValue(shippingMethod);
  }, [shippingMethod]);

  useEffect(() => {
    if (!showLoader && !isAmazonpay) {
      fetchShippingRatesRequest();
    }
  }, [showLoader, isAmazonpay, shippingAddress]);

  useEffect(() => {
    if (isReadyForShippingRatesCheck && shippingRates.length === 0 && cartItems.length > 0) {
      const cartSkus = cartItems.map(cartItem => cartItem.sku);

      nrError(noShippingRatesError, new Error(noShippingRatesError), { cartSkus });
    }
  }, [isReadyForShippingRatesCheck, shippingRates, cartItems]);

  useEffect(() => {
    // Don't change anything automatically if we are in the final afterpay step
    if (!value || !filteredShippingRates || isAfterpayCompleteStep) {
      return;
    }

    // If free shipping available, auto select
    const freeShippingRate = filteredShippingRates.find(shippingRate => shippingRate.amount === 0);

    if (freeShippingRate && !hasFreeShippingBeenApplied) {
      setFreeShippingApplied(true);
      setValue(buildShippingMethodStr(freeShippingRate.carrier_code)(freeShippingRate.method_code));

      if (!isFreeShippingAppliedToCart) {
        updateShippingMethod(freeShippingRate.carrier_code, freeShippingRate.method_code, isAmazonpay);
      }

      return;
    }

    if (!freeShippingRate && hasFreeShippingBeenApplied) {
      setFreeShippingApplied(false);
    }

    if (
      isReadyForShippingRatesCheck &&
      !filteredShippingRates.find(
        shippingRate => buildShippingMethodStr(shippingRate.carrier_code)(shippingRate.method_code) === value
      )
    ) {
      if (!filteredShippingRates.length) {
        return;
      }

      setValue(buildShippingMethodStr(filteredShippingRates[0].carrier_code)(filteredShippingRates[0].method_code));
      updateShippingMethod(filteredShippingRates[0].carrier_code, filteredShippingRates[0].method_code, isAmazonpay);
    }
  }, [isReadyForShippingRatesCheck, filteredShippingRates, isAfterpayCompleteStep]);

  return (
    <Show when={!isVirtualCart}>
      <Fragment>
        <ShippingSelectionHeader>
          <HeaderText secondary>Shipping Options</HeaderText>

          {showFrameMessaging && <FrameMessaging />}

          <ShippingSubtextBlock id="shipping_options_sub_header" />
        </ShippingSelectionHeader>
        <form onChange={onChange}>
          <Show when={isLoading}>
            <ShippingMethodsSkeleton />
          </Show>
          <Show when={isReadyForShippingRatesCheck && shippingRates.length === 0}>
            <ErrorMessage>{noShippingRatesErrorMessage}</ErrorMessage>
          </Show>
          <Show when={!isLoading}>
            <>
              {filteredShippingRates.map((shippingRate: QuoteDataShippingMethod, i) => {
                const getEstArrivalDateText = () => {
                  const dateRange = getEstimatedArrivalDateRange(
                    shippingRate.extension_attributes.min_shipping_time,
                    shippingRate.extension_attributes.max_shipping_time,
                    longestSLA
                  );

                  return `Estimated Arrival ${dateRange}`;
                };

                const deadlineMsg = optionGet('extension_attributes.deadline_messaging')(shippingRate).getOrElse('');

                return (
                  <ShippingMethodItemWrapper key={i}>
                    <RadioInputSelector
                      value={buildShippingMethodStr(shippingRate.carrier_code)(shippingRate.method_code)}
                      onChange={e => setValue(e.target.value)}
                      checked={buildShippingMethodStr(shippingRate.carrier_code)(shippingRate.method_code) === value}
                    >
                      <ShippingMethod>
                        <StyledH4 secondary>
                          {removeAlternateFromShippingMethodTitle(shippingRate.method_title)}
                          {windowSize.width < 768 ? ` ($${shippingRate.amount})` : ''}
                        </StyledH4>
                        <Subtext>{cartItems.length && getEstArrivalDateText()}</Subtext>
                      </ShippingMethod>
                      <Show when={windowSize.width >= 768}>
                        <ShippingMethodPrice secondary>${shippingRate.amount}</ShippingMethodPrice>
                      </Show>
                    </RadioInputSelector>
                    {showIf(deadlineMsg)(<HolidayNoteText dangerouslySetInnerHTML={{ __html: deadlineMsg }} />)}
                  </ShippingMethodItemWrapper>
                );
              })}
            </>
          </Show>
        </form>
      </Fragment>
    </Show>
  );
};

const FrameMessaging = () => (
  <div className="shipping-method-options-frame-messaging">
    <strong>A Note on our Frames Packaging:</strong>
    <div>
      Our packaging process is meticulously crafted to provide maximum protection for your frame. Each
      frame is carefully wrapped in multiple layers of protective material ensuring it's securely cushioned
      against potential damage during transit. It comes ready to be displayed on your wall immediately.
    </div>
  </div>
);

const ShippingSubtextBlock = ({ id }: { id: string }) => (
  <Block
    render={content => (
      <SubheaderText id="shipping-sub-header-content" secondary dangerouslySetInnerHTML={{ __html: content }} />
    )}
    id={id}
  />
);

const mapStateToProps = (state: GlobalState) => ({
  hasFreeShippingBeenApplied: hasFreeShippingBeenAppliedSelector(state),
  shippingRates: shippingRatesSelector(state),
  shippingMethod: shippingMethodSelector(state),
  shippingRatesLoadingState: shippingRatesLoadingStateSelector(state),
  shippingAddressLoadingState: shippingAddressLoadingStateSelector(state),
  shippingAddress: shippingAddressSelector(state),
  isVirtualCart: isVirtualCartSelector(state),
  cartItems: cartItemsSelector(state),
  totalSegments: totalSegmentsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchShippingRatesRequest: () => dispatch(_fetchShippingRatesRequest()),
  setFreeShippingApplied: hasBeenApplied => dispatch(_setFreeShippingApplied(hasBeenApplied))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingTypeSelector);
