import { TrackingProp } from 'react-tracking';
import { useEffect, useRef } from 'react';
import { CartViewedEvent } from '../analyticsWrapper';
import { Cart__Patched } from '../../../pages/checkout/cart';
import { CartViewedProperties } from 'itly';
import { cartItemToAnalyticsProduct, createCartProperties, createShippingMethodProperties } from '../tracking';
import { useProductSLA } from '../../../hooks/useProductSLA';

export function useTrackCartViewed(tracking: TrackingProp<CartViewedEvent>, cart: Cart__Patched): void {
  const hasTrackedView = useRef(false);

  const numCartItems = cart.items && cart.items.length ? cart.items.length : 0;
  const { getLongestSLAFromCart } = useProductSLA();
  const longestSLA = getLongestSLAFromCart(cart);

  useEffect(() => {
      if (hasTrackedView.current || cart.entity_id === undefined || cart.items === undefined) {
        return;
      }

      hasTrackedView.current = true;
      trackCartViewed(tracking, cart, longestSLA);
    }
    , [cart.entity_id, numCartItems, cart, longestSLA, tracking]);
}

function trackCartViewed(tracking: TrackingProp<CartViewedEvent>, cart: Cart__Patched, longestSLA = null) {
  const products = cart.items.map(cartItemToAnalyticsProduct);
  const shippingProperties = createShippingMethodProperties(cart, longestSLA);
  const cartProperties = createCartProperties(cart);
  const data: CartViewedProperties = {
    cart_id: cart.entity_id,
    products,
    ...cartProperties,
    shipping_properties: shippingProperties
  };
  tracking.trackEvent({ type: 'cartViewed', data });
}
