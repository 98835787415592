import styled from 'styled-components';
import { mediaQuery } from 'component-library';

export const Content = styled.div`
  width: 900px;
  margin: 0 auto;
  max-width: 95%;
`;

export const GlobalLayoutWrapper = styled.div`
  min-height: 100vh;
`;

export const Container = styled.section<{contentPage: boolean, width?: string}>`
  position: relative;
  width: 100%;
  padding-left: ${({ contentPage }) => (contentPage ? '0' : '1rem')};
  padding-right: ${({ contentPage }) => (contentPage ? '0' : '1rem')};
  margin: 0rem auto 0rem;

  ${mediaQuery.medium`
    max-width: ${({ contentPage, width }) => (contentPage ? '100%' : width ? width : '1200px')};
  `};
`;

export const PromoBanner = styled.div`
  margin: 0 !important;
`;

export const AccountPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10%;
  @media (max-width: 780px) {
    padding-left:0;
  }
`;
