import { useCallback } from 'react';
import { GetAllProjectsRequest } from 'au-js-sdk/lib/api/requests/projects/getAllProjects';
import { useApiRequest } from '../useApiRequest';
import { HTTP_DELETE, HTTP_POST, HTTP_GET } from '../../helpers/http';
import { HydratedProjectV1, ProjectRequestResponse } from '../../types/projects';
import { ProjectStatusV1 } from 'au-js-sdk/lib/models/ProjectV1';
import { DuplicateProjectResponse } from 'au-js-sdk/lib/api/models/cc/project/DuplicateProjectResponse';

const getOptionsFromMagentoOptions = (options: { option_id: string; option_value: string }[]) =>
  options.reduce((opts, opt) => ({ ...opts, [opt.option_id]: opt.option_value }), {});

export const hydrateProjectType = (project: ProjectRequestResponse): HydratedProjectV1 => {
  try {
    const projectData = JSON.parse(project.projectData || '{}').data;
    const projectVersion = '1';

    return {
      id: project.projectId,
      name: projectData.project.name,
      version: projectVersion,
      product: projectData.product,
      options: getOptionsFromMagentoOptions(projectData.product.magento.options),
      data: projectData,
      thumbnail: project.projectThumbnail,
      adminEditDate: project.adminEditDate ? new Date(project.adminEditDate) : undefined,
      adminEditor: project.adminEditor,
      createdAt: new Date(project.createdAt),
      markedForDeletion: project.markedForDeletion,
      modifiedAt: new Date(project.modifiedAt),
      binaryData: project.projectBinaryData,
      sourceProjectId: project.sourceProjectId,
      state: project.state,
      type: project.type,
      user: project.user
    };
  } catch {
    throw new Error('There was an error trying to hydrate project data');
  }
};

export const useProjectServiceRequest = ({ flashId, token, url }: GetAllProjectsRequest & { url?: string }) => {
  const projectsRequest = useApiRequest(url || '', false);

  const fetchProject = useCallback(
    (projectId: string): Promise<HydratedProjectV1> =>
      projectsRequest(
        'project',
        projectId,
        `?user=${flashId}`
      )(HTTP_GET)({
        Authorization: `Bearer ${token}`
      })<ProjectRequestResponse>().then(hydrateProjectType),
    [flashId, token, url]
  );

  const fetchRawProject = useCallback(
    (projectId: string): Promise<ProjectRequestResponse> =>
      projectsRequest('project', projectId, `?user=${flashId}`)(HTTP_GET)({
        Authorization: `Bearer ${token}`
      })<ProjectRequestResponse>(),
    [flashId, token, url]
  );

  const deleteProject = useCallback(
    (projectId: string): Promise<boolean> =>
      projectsRequest('project', projectId, `?user=${flashId}`)(HTTP_DELETE)({
        Authorization: `Bearer ${token}`
      })<boolean>(),
    [flashId, token, url]
  );

  const duplicateProject = useCallback(
    (projectId: string): Promise<DuplicateProjectResponse> =>
      projectsRequest('project', 'duplicate', `?user=${flashId}&projectId=${projectId}`)(HTTP_POST)({
        Authorization: `Bearer ${token}`
      })(),
    [flashId, token, url]
  );

  const restoreProject = useCallback(
    (projectId: string): Promise<void> =>
      projectsRequest('project', 'restore', `?user=${flashId}&projectId=${projectId}`)(HTTP_POST)({
        Authorization: `Bearer ${token}`
      })(),
    [flashId, token, url]
  );

  const updateProjectStatus = useCallback(
    (projectId: string, status: ProjectStatusV1): Promise<void> =>
      projectsRequest('project', projectId, `?user=${flashId}&overrideLock=false`)(HTTP_POST)({
        Authorization: `Bearer ${token}`
      })({
        projectId: projectId,
        user: flashId,
        state: status
      }),
    [flashId, token, url]
  );

  const updateProject = useCallback(
    (projectId: string, projectData: any, overrideLock = false): Promise<ProjectRequestResponse> =>
      projectsRequest('project', projectId, `?user=${flashId}&overrideLock=${overrideLock}`)(HTTP_POST)({
        Authorization: `Bearer ${token}`
      })({
        projectId: projectId,
        user: flashId,
        projectData: JSON.stringify(projectData)
      }),
    [flashId, token, url]
  );

  return {
    deleteProject,
    duplicateProject,
    fetchProject,
    fetchRawProject,
    restoreProject,
    updateProjectStatus,
    updateProject
  };
};
